import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "user", sortable: true },
        { key: "email", sortable: true },
        { key: "role", sortable: true },
        { key: "company", sortable: true },
        { key: "status", sortable: true },
        { key: "actions" },
    ];
    const perPage = ref(25);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const companyFilter = ref(null);
    const statusFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value,
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    const refreshData = () => {};

    watch(
        [
            currentPage,
            perPage,
            searchQuery,
            roleFilter,
            companyFilter,
            statusFilter,
        ],
        () => {
            refetchData();
        }
    );
    const fetchUser = (ctx, id) => {
        
        store.dispatch("users/fetchUser", id).then(response => {
            console.log(response)
        }).catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: "Error fetching this user",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                },
            });
        });
    
    }

    const fetchUsers = (ctx, callback) => {

        console.log("loggin company filter", companyFilter.name)
        console.log("loggin role filter", roleFilter.value)

        store
            .dispatch("users/fetchUsers", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                role: roleFilter.value,
                company: companyFilter.value,
                status: statusFilter.value,
            })
            .then((response) => {
                const { users, total } = response.data;

                callback(users);
                totalUsers.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching users list",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            });
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = (role) => {
        if (role === "Super Admin") return "danger";
        if (role === "Admin") return "info";
        if (role === "User") return "primary";
        if (role === "Driver") return "success";
        return "primary";
    };

    const resolveUserRoleIcon = (role) => {
        if (role === "Super Admin") return "ServerIcon";
        if (role === "Admin") return "DatabaseIcon";
        if (role === "User") return "UserIcon";
        if (role === "Driver") return "UserIcon";
        return "UserIcon";
    };

    const resolveUserStatusVariant = (status) => {
        if (status === "Pending") return "warning";
        if (status === "Active") return "success";
        if (status === "Inactive") return "secondary";
        return "primary";
    };

    return {
        fetchUser,
        fetchUsers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        refetchData,
        refreshData,

        // Extra Filters
        roleFilter,
        companyFilter,
        statusFilter,
    };
}
