import axios from "@axios";
import { serverUri } from '@/config';
import { getUserData } from "@/auth/utils"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {
        ADD_USER (state, user) {
            state.userList.push(user);
        },
        SET_USERS (state, users) {
            state.userList = users;
        }
    },
    actions: {
        addUserAction({commit}, payload){
            return new Promise((resolve, reject) => {
                axios
                .post(`${serverUri}/users`, payload)
                .then(response => {
                    commit('ADD_USER', response.data.data);
                    resolve(response);
               } )})
        },
        fetchUsers(ctx, queryParams) {
            return new Promise((resolve) => {
              const {
                q = '',
                perPage = 10,
                page = 1,
                sortBy = 'id',
                sortDesc = false,
                role = null,
                company = null,
                status = null,
              } = queryParams;
              
              const queryLowered = q.toLowerCase();
              let array = ctx.state.userList;
              
              if (getUserData()) {
               
                if (getUserData().role === 'Super Admin') array = ctx.state.userList;
                else array = ctx.state.userList.filter(user => user.companyID === getUserData().company_id );
              }
              
              const filteredData = array.filter(
                user =>
                  (user.username.toLowerCase().includes(queryLowered) || user.fullName.toLowerCase().includes(queryLowered)) &&
                  user.role === (role || user.role) &&
                  user.company === (company || user.company_name) &&
                  user.status === (status || user.status),
              );
              
              const sortedData = filteredData.sort(sortCompare(sortBy));
              if (sortDesc) sortedData.reverse();
              
              resolve({
                data: {
                  users: paginateArray(sortedData, perPage, page),
                  total: filteredData.length,
                }
              });
            });
        },
        fetchUser(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${serverUri}/users/getUserDetails`, id)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addUser(ctx, userData) {
            return new Promise(async (resolve, reject) => { // added async here
        
                const { user } = userData;
        
                // Assign Status
                user.status = 'Active'
        
                let newUser = {
                    // id: Number(store.state.users.userList[store.state.users.userList.length - 1].id) + 1,
                    fullName: user.full_name,
                    username: user.full_name,
                    email: user.email,
                    company: user.company_name,
                    role: user.role_id,
                    status: user.status,
                    password: user.password
                };
        
                try {
                    const response = await axios.post(`${serverUri}/users/saveuser`, newUser); // await here
                    ctx.state.users.userList = response.data; // Use ctx to access state
                    
                    ctx.commit('users/addUser', ctx.state.users.userList); // Use ctx to commit
        
                    resolve(response.data); // Resolve promise with the response data
                } catch (error) {
                    reject(error); // If there's an error, reject the promise with the error
                }
            });
        },
        
        updateUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/apps/user/update`, { user: userData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteUser(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/apps/user/users/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        getuserpermission(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${serverUri}/users/getuserpermission/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            })
        }
    },
};
