<template>
<div>

    <!-- Media -->
    <b-media class="mb-2 edit-header">
        <template #aside>
            <b-avatar ref="previewEl" :src="userData.avatar" :text="avatarText(userData.fullName)" :variant="`light-${resolveUserRoleVariant(userData.role)}`" size="90px" rounded />
        </template>
        <h4 class="mb-1">
            {{ userData.fullName }}
        </h4>
        <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="uploadImageToServer"
          >
          <span class="d-none d-sm-inline">Upload</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer ref="simpleRules">
        <b-form @submit.prevent="onUpdate" @reset.prevent="resetForm">
            <b-row>

                 <!-- Field: Role -->
                 <b-col cols="12" md="4">
                    <b-form-group label="User Role" label-for="user-role">
                        <v-select v-model="selectedRole" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="roleOptions" :clearable="false" input-id="user-role" @input="onChangeRole()"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="4" v-if="showDriver">
                    <b-form-group label="Driver" label-for="driver" >
                                <v-select
                                v-model="driver"
                                label="label"
                                :options="option"
                                @input="onChange()"
                                placeholder="Select Driver"
                               
                            />
                        </b-form-group>
                </b-col>

                <!-- Field: Full Name -->
                <b-col cols="12" md="4">
                    <b-form-group label="Name" label-for="full-name">
                        <validation-provider #default="{ errors }" name="Name" rules="required">
                            <b-form-input id="full-name" v-model="userData.fullName" :state="errors.length > 0 ? false:null" placeholder="Enter Name" :readonly="userData.role==='Driver'"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Email -->
                <b-col cols="12" md="4">
                    <b-form-group label="Email" label-for="email">
                        <validation-provider #default="{ errors }" name="Email" rules="required|email">
                            <b-form-input v-model="userData.email" :state="errors.length > 0 ? false:null" type="email" placeholder="Your Email" :readonly="userData.role=='Driver' ? true : false"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Status -->
                <b-col cols="12" md="4">
                    <b-form-group label="Status" label-for="user-status">
                        <v-select v-model="userData.status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="statusOptions" :reduce="val => val.value" :clearable="false" input-id="user-status" />
                    </b-form-group>
                </b-col>

               

                <!-- Field: Company Select -->
                <b-col cols="12" md="4">
                    <b-form-group label="Company" label-for="company">
                        <v-select v-if="loggedUser.role === 'Super Admin'" v-model="userData.company_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="companiesList" :reduce="val => val.value" :clearable="false" input-id="company" />
                    </b-form-group>
                </b-col>

                <!-- Field: Password Input -->
                <b-col cols="6">
                    <b-form-group label="Password" label-for="a-password">
                        <validation-provider #default="{ errors }" name="Password" vid="Password" rules="required|min:8|password">
                            <b-input-group>
                                <b-form-input id="a-password" v-model="userData.password" :state="errors.length > 0 ? false:null" 
                                    :type="passwordFieldType" placeholder="Password" />
                                <b-input-group-append is-text>
                                    <feather-icon
                                    :icon="passwordToggleIcon"
                                    class="cursor-pointer"
                                    @click="togglePassword"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- confirm password -->
                <b-col cols="6">
                    <b-form-group label="Confirm Password" label-for="ac-password">
                        <validation-provider #default="{ errors }" name="Confirm Password" rules="required|confirmed:Password">

                            <!-- <b-form-input id="ac-password" v-model="userData.password2" :state="errors.length > 0 ? false:null" type="password" placeholder="Confirm Password" /> -->
                            <b-input-group>
                                <b-form-input id="ac-password" v-model="userData.password2" :state="errors.length > 0 ? false:null" 
                                    :type="passwordFieldTypeConfirm" placeholder="Confirm Password" />
                                <b-input-group-append is-text>
                                    <feather-icon
                                    :icon="passwordToggleIconConfirm"
                                    class="cursor-pointer"
                                    @click="togglePasswordConfirm"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <!-- Permissions checkboxes -->
                <b-col cols="12" md="12">
                    <b-row class="permission">
                        <b-col sm="3" v-if="loggedUser.role === 'Super Admin' || loggedUser.role === 'Admin'" v-for="option in options" :key="option.value">
                            <!-- <b-form-checkbox v-model="selected" :value="option.value" name="flavour-3a" :disabled="disable[option.value]"> -->
                                <div>
                                    <!-- {{ option.value }} -->
                                    <b-form-checkbox v-model="selected" :value="option.value" name="flavour-3a" :disabled="disableCheckBoxs(userData.role,option.value)">
                                        {{ option.text }}
                                    </b-form-checkbox>
                                </div>
                           
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <!-- Action Buttons -->
            <b-button type="submit" :block="$store.getters['app/currentBreakPoint'] === 'xs'" variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1">
                Save Changes
            </b-button>
            <b-button variant="outline-secondary" type="submit" @click.prevent="validationForm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Reset
            </b-button>
        </b-form>
    </validation-observer>
</div>
</template>

<script>
import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend
} from 'bootstrap-vue'
import {
    avatarText
} from '@core/utils/filter'
import vSelect from 'vue-select'
//import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {
    ref, toRefs,
} from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import { getUserData } from "@/auth/utils";
import {
    required,
    min,
    confirmed,
} from '@validations'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import http from '@/services/http'
import store from '@/store'
import {
    serverUri
} from '@/config'

export default {
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        BFormCheckboxGroup,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        ToastificationContent,
        BInputGroup,
        BInputGroupAppend
    },
    data() {
        return {
            loggedUser: getUserData(),
            userData:{
                fullName:'',
                role:'',
            },
            driver:'',
            disable:[],
            password: '',
            password2: '',
            roleOptions: [],
            companiesList: [],
            selected: [],
            selectedRole: {label: this.userData.role, value: this.userData.role_id},
            allDrivers:[],
            options: [],
            option:[],
            checked: [],
            required,
            min,
            confirmed,
            passwordFieldType: 'password',
            passwordFieldTypeConfirm: 'password',
            company_id:getUserData().company_id,
            showDriver:false
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },

        passwordToggleIconConfirm() {
            return this.passwordFieldTypeConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        }
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
        userPermissions: {
            type: Array
        }
    },
    methods: {

        onChange() {
            
            if(this.driver.title)
            {
                this.userData.fullName = this.driver.label;

                this.allDrivers.map(item => {
                    if(item.id == this.driver.title)
                    {
                        this.userData.fullName      = item.driver_name;
                        this.userData.email         = item.email;
                        this.userData.company_id    = item.company_id;
                    }
                });
            }
        },
        disableCheckBoxs(role,value)
        {
            if(role == 'Driver')
            {
                if(value == 'manage-driver')
                {
                    return false;
                } else {
                    return true;
                }
            } else  {

                
                if(value == 'manage-driver')
                {
                    
                    return true;

                } else {

                    
                    return false;
                }
            }
        },
        
        onChangeRole() {

            this.$emit('update-role', this.selectedRole);
        
            if(this.selectedRole.label == 'Driver')
            {
                this.showDriver = true;
                this.selected   = ['manage-driver'];

            } else {
                this.showDriver             = false;
                this.driver                 = '';
                this.selected               = this.userPermissions;

                this.userPermissions.map(item => {
                    this.disable[item]= false;
                });
                
            }
        },

        uploadImageToServer() {
            const fileInput = this.$refs.refInputEl.files[0]; // Get the file from the input
            if (!fileInput) return;

            const formData = new FormData();
            formData.append('image', fileInput);

            http.post(`${serverUri}/users/uploaduserimage`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${getUserData().accessToken}`,
                },
            })
            .then(response => {
                // Handle response, e.g., setting the image URL to what the backend returns
                console.log(response.data);
                this.userData.avatar = response.data.imageUrl; // Assuming backend returns the image URL in S3
                this.showToast('success', 'Success', 'Image uploaded successfully');
            })
            .catch(error => {
                // Handle error
                console.error(error);
                this.showToast('danger', 'Error', 'Failed to upload image');
            });
        },
        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: 'BellIcon',
                    text: text,
                    variant,
                },
            })
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    alert('password reset!')
                }
            })
        },
        onUpdate() {
            axios.post(`${serverUri}/users/userpermission`, 
            { 
                permission: this.selected, 
                id: this.userData.id,

            }).then(res => {
                this.$emit('update-role', this.selectedRole);
                this.onUpdateUser();
                this.showToast('success', 'Success', 'The user data has been updated')

                this.$router.push({ name: 'apps-users-list' });
            })
        },

        togglePassword() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },

        togglePasswordConfirm() {
            this.passwordFieldTypeConfirm = this.passwordFieldTypeConfirm === 'password' ? 'text' : 'password'
        },
    },
    created() {

        setTimeout(() => {
            this.selected = this.userPermissions;

            this.onChangeRole();

            if(this.userData.role == 'Driver')
            {
                this.allDrivers.map(item => {
                        if(item.email == this.userData.email)
                        {
                            this.driver = {label:item.driver_name,title:item.id};
                        }
                    });
            }

        }, 1000);
        //this.roleOptions = store.dispatch("user/getRoles");
        axios.get(`${serverUri}/users/getrole`).then(res => {
            res.data.map(item => {
                this.roleOptions.push({
                    label: item.name,
                    value: item.id,
                });
            })
        });

        axios.get(`${serverUri}/drivers/alldriversdata/${this.company_id}`).then((res) => {
            res.data.map((item) => {
                this.allDrivers.push(item);
                this.option.push({ title: item.id, label: item.driver_name });
            })
        });

        axios.get(`${serverUri}/users/getcompany`).then(res => {
            res.data.map(item => {
                this.companiesList.push({ label: item.name, value: item.id })
            })
        })
        axios.get(`${serverUri}/users/getpermission`)
            .then(res => {
                const uniqueActions = new Set(); // Create a Set to store unique actions
                res.data.forEach(item => {
                const action = item.action === 'manage-roaster' ? 'manage-roster' : item.action;
                uniqueActions.add(action);//.split('manage-')[1]); // Add the processed action to the Set
                });
                
                // Convert the Set back to an array and add each unique option to this.options
                uniqueActions.forEach(action => {
                this.options.push({ value: action, text: action });
                });
            });

        /*axios.get(`${serverUri}/users/getpermission`).then(res => {
            res.data.map(item => {
                const action = item.action == 'manage-roaster' ? 'manage-roster' : item.action;
                this.options.push({ value: item.action, text: action.split('manage-')[1] })
            })
        })*/
    },
    setup(props) {

        const {userData} = toRefs(props);
        const {
            
            resolveUserRoleVariant,
            refreshData
        } = useUsersList()

        // let roleOptions = [];

        const statusOptions = [{
                label: 'Pending',
                value: 'pending'
            },
            {
                label: 'Active',
                value: 'active'
            },
            {
                label: 'Inactive',
                value: 'inactive'
            },
        ]

        // ? Demo Purpose => Update image on click of update
        const refInputEl = ref(null)
        const previewEl = ref(null)

        
        const onUpdateUser = () => {
            store.dispatch('users/updateUser', userData.value)
                .then(() => {
                    // eslint-disable-next-line no-use-before-define
                    refreshData()
                })
        }

        /*const {
            inputImageRenderer
        } = useInputImageRenderer(refInputEl, base64 => {
            // eslint-disable-next-line no-param-reassign
            userData.avatar.value = base64
        })*/

        return {
            resolveUserRoleVariant,
            refreshData,
            onUpdateUser,
            avatarText,
            // roleOptions,
            statusOptions,
            refInputEl,
            previewEl,
            //inputImageRenderer,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.edit-header {
    display: flex;
    align-items: center;
}
</style><style>
.permission {
    margin: 10px 0;
}

.permission div {
    margin: 2px 0;
}
</style>
